import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

export default function Footer2() {

    function openCalendly() {
        console.log("LOL")
        window.open('https://calendly.com/copywriter_jm/email?back=1&month=2023-06')
    }

    return(    
        <footer>
            <div class="footer mt-24">
                <div className="row sm:text-xl lg:text-sm">
                    <a href="/contact"><i class="fa fa-envelope pr-6"></i></a>
                    <a href='https://twitter.com/Copywriter_JM'><i><FontAwesomeIcon icon={faTwitter} /></i></a>
                </div>

                <div className="row sm:pt-12 sm:pb-12 lg:pt-6 lg:pb-6">
                    <ul className='sm:text-xl'>
                        <li><a href="/contact">Contact</a></li>
                        <li><a href="/services">Services</a></li>
                        <li><a href="/about">About</a></li>
                        <li><a href="/newsletter">Newsletter</a></li>
                    </ul>
                </div>

                <div class="text-white">
                    <h1 className='text-2xl'>
                    © All Rights Reserved | Design & Development by <a className=' text-red-500 text-xl' target="_blank" href='https://twitter.com/petarjoshevski'>Petar</a>
                    </h1>
                    {/* Copyright © 2023 Petar Joshevski - All rights reserved || Designed By: Petar */}
                </div>
            </div>
        </footer>
    )
}