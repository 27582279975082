import React from 'react'

export default function Service(props) {
    return(
        // <div className={`${props.bg} pt-12 pl-12 pr-12 pb-12 ml-12 mr-12 rounded-2xl drop-shadow-2xl lg:w-4/12 sm:mt-12`}>
        //     <img src={`${props.img}`} className=' w-2/12 mb-6'/>
        //     <h1 className={`${props.tcolor} font-bold lg:text-2xl mb-3 sm:mb-6 sm:text-5xl`}>{props.head}</h1>
        //     <h1 className={`${props.tcolor} sm:text-3xl lg:text-base`}>{props.text}</h1>
        // </div>
        <div className={`${props.bg} invisible pt-12 pl-12 pr-12 pb-12 2xl:ml-12 2xl:mr-12 lg:ml-6 lg:mr-6 rounded-2xl drop-shadow-2xl 2xl:w-4/12 lg:w-4/12 sm:mt-12 ${props.anim}`}>
            <img src={`${props.img}`} className=' w-2/12 mb-6'/>
            <h1 className={`${props.tcolor} font-bold lg:text-2xl lg:mb-3 sm:mb-6 sm:text-5xl`}>{props.head}</h1>
            <h1 className={`${props.tcolor} sm:text-3xl lg:text-base`}>{props.text}</h1>
        </div>
    )
}