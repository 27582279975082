import React, { useState, useRef, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

export default function About() {

    const { ref: text1, inView: text1IsVisible} = useInView({"threshold": 0, "triggerOnce": true})
    const { ref: text2, inView: text2IsVisible} = useInView({"threshold": 0, "triggerOnce": true})
    const { ref: text3, inView: text3IsVisible} = useInView({"threshold": 0, "triggerOnce": true})
    const { ref: text4, inView: text4IsVisible} = useInView({"threshold": 0, "triggerOnce": true})
    const { ref: text5, inView: text5IsVisible} = useInView({"threshold": 0, "triggerOnce": true})
    const { ref: text6, inView: text6IsVisible} = useInView({"threshold": 0, "triggerOnce": true})
    const { ref: text7, inView: text7IsVisible} = useInView({"threshold": 0, "triggerOnce": true})

    const { ref: buttonAnim, inView: buttonAnimIsVisible} = useInView({"threshold": 0, "triggerOnce": true})

    function openCalendly() {
        console.log("LOL")
        window.open('https://calendly.com/copywriter_jm/email?back=1&month=2023-06')
    }

    return(
        <div>
            <h1 className='text-center text-7xl pt-48 bg-blue-main text-white pb-24 lg:text-5xl lg:pt-40 lg:pb-20'>About me and how I became an email copywriter</h1>
            <div className='lg:pr-40 lg:pl-40'>
                <h1 className='text-6xl font-bold ml-12 mt-24 2xl:text-4xl lg:text-3xl lg:mt-20 overflow-hidden bg-white'><span className='inline-block lineUp'>My name is Jordan</span></h1>
                <h1 className='text-5xl ml-12 mt-12 mr-12 2xl:text-2xl lg:text-xl 2xl:mt-6 lg:mt-3 overflow-hidden bg-white'>
                    <span className='inline-block lineUp2'>I was born and raised in East London with Jamaican heritage. I have a solid educational background with an Undergraduate degree 
                    in Economics and a Master's degree in Banking and Finance.</span></h1>
            </div>
            <div className='lg:flex 2xl:mt-20 lg:mt-10'>
                <img src="./images/jordan_pc.png" className='2xl:ml-28 lg:ml-8 lg:pt-12 lg:pb-12 lg:pr-6 lg:pl-6 pt-6 pb-6 sm:hidden lg:block 2xl:w-4/12 lg:w-5/12 
                object-cover object-center slide-in'/>
                <div className='2xl:pr-20 lg:pr-10 2xl:ml-10 lg:ml-0'>
                    <h1 className='text-6xl font-bold ml-12 mt-24 mr-12 2xl:text-4xl lg:text-3xl lg:mt-0 overflow-hidden bg-white'>
                        <span ref={text1} className={`inline-block ${text1IsVisible ? `lineUp3` : ''} invisible`}>How did I end up as an email copywriter?</span></h1>
                    <h1 className='text-5xl ml-12 mt-12 mr-12 2xl:text-2xl lg:text-xl 2xl:mt-6 lg:mt-3 overflow-hidden bg-white'>
                        <span ref={text2} className={`inline-block ${text2IsVisible ? `lineUp4` : ''} invisible`}>Although email copywriting initially came as a challenge to me because it is quite dissimilar to my degree,
                         I embraced it enthusiastically. There is nothing more fulfilling than overcoming such an obstacle.</span></h1>
                    <h1 className='text-5xl ml-12 mt-12 mr-12 2xl:text-2xl lg:text-xl 2xl:mt-3 lg:mt-2 overflow-hidden bg-white'>
                        <span ref={text3} className={`inline-block ${text3IsVisible ? `lineUp5` : ''} invisible`}>The idea of combining the art of writing with consumer psychology to generate sales intrigued me. It's
                         an amazing journey to understand how words can influence and inspire people to take action.</span></h1>
                    <div className='sm:hidden lg:block'>
                        <h1 className='text-6xl font-bold ml-12 mt-24 mr-12 2xl:text-4xl lg:text-3xl lg:mt-12 overflow-hidden bg-white'>
                            <span ref={text4} className={`inline-block ${text4IsVisible ? `lineUp` : ''} invisible`}>Other than that</span></h1>
                        <h1 className='text-5xl ml-12 mt-12 mr-12 2xl:text-2xl lg:mt-6 lg:text-xl overflow-hidden bg-white'>
                            <span ref={text5} className={`inline-block ${text5IsVisible ? `lineUp2` : ''} invisible`}>I love the adrenaline rush from sport. Whether it's boxing, football or basketball, I make it a 
                            point to engage in each activity at least once a week. And, of course, I also make time for the gym to maintain my physical well-being.</span></h1>
                    </div>
                </div>
            </div>

            <div className='lg:hidden sm:block'>
                <img src="./images/jordan_pc.png" className='pl-12 pr-12 pt-24'/>
                <h1 className={`text-6xl font-bold ml-12 mt-24 mr-12 lg:text-5xl overflow-hidden bg-white invisible`}>
                    <span ref={text6} className={`inline-block ${text6IsVisible ? `lineUp` : ''}`}>Other than that</span></h1>
                <h1 className={`text-5xl ml-12 mt-12 mr-12 overflow-hidden bg-whiteinvisible `}>
                    <span ref={text7} className={`inline-block ${text7IsVisible ? `lineUp2` : ''} `}>I love the adrenaline rush from sport. Whether it's boxing, football or basketball, I make it a point to engage in each 
                    activity at least once a week. And, of course, I also make time for the gym to maintain my physical well-being.</span></h1>
            </div>
            <div className='flex justify-center items-center mt-24'>
                <button ref={buttonAnim} className={`btn ${buttonAnimIsVisible ? 'slide-in-long' : ''} invisible`} onClick={openCalendly}>Book a free call</button>
            </div>
        </div>
    )
}