import React, { useState, useRef, useEffect } from 'react'

export default function Navbar() {

    const [agreement, setAgreement] = useState(false);
    const navTitle = useRef()
    const navbarRef = useRef()

    const handleChange = (event) => {
        setAgreement(event.target.checked);
    }

    useEffect(() => {
        if(agreement == true) {
            navTitle.current.style.zIndex = -1
        } else {
            navTitle.current.style.zIndex = 1
        }
    }, [agreement])


    
    


    return(
        <div className='sticky top-0 z-50'>
            <nav class="screen2_menu_container">
                <h1 className='nav-title text-5xl text-white font-extrabold lg:text-4xl' ref={navTitle}>Jordan Metcalfe</h1>
                <ul className='nav--links'>
                    <li className='hover-underline-animation'><a href='/'>Home</a></li>
                    <li className='hover-underline-animation'><a href='/about'>About</a></li>
                    <li className='hover-underline-animation'><a href="/services">Services</a></li>
                    <li className='hover-underline-animation mr-0'><a href="/contact">Contact</a></li>
                    <li className='hover-underline-animation mr-6'><a href='/newsletter'>Newsletter</a></li>

                </ul>


                <label for="screen2-menu_check" class="screen2_menu_btn">
                    <input type="checkbox" id="screen2-menu_check" onChange={handleChange}/>

                    <div class="screen2_menu_hamburger"></div>

                    <ul class="screen2_menu_items">
                        <li><a href='/'>Home</a></li>
                        <li><a href='/about'>About</a></li>
                        <li><a href="/services">Services</a></li>
                        <li><a href="/contact">Contact</a></li>
                        <li><a href="/newsletter">Newsletter</a></li>
                    </ul>
                </label>
            </nav>
        </div>
    )
}