import React from 'react'
import Service from './Service'
import { useInView } from 'react-intersection-observer'

export default function Services() {

    const { ref: check1, inView: check1IsVisible} = useInView({"threshold": 0, "triggerOnce": true})
    const { ref: check2, inView: check2IsVisible} = useInView({"threshold": 0, "triggerOnce": true})
    const { ref: check3, inView: check3IsVisible} = useInView({"threshold": 0, "triggerOnce": true})

    function openCalendly() {
        console.log("LOL")
        window.open('https://calendly.com/copywriter_jm/email?back=1&month=2023-06')
    }

    return(
        <div className='2xl:mt-56 lg:mt-32 2xl:ml-32 2xl:mr-32 lg:ml-12 lg:mr-12 sm:ml-32 sm:mr-32 sm:mt-56'>
            <div className='lg:flex'>
                <Service anim="lineUp" bg="bg-blue-main" img="./images/ecommerce.png" head="eCommerce Copywriting" tcolor="text-white" shadow="drop-shadow-2xl"
                text="Our primary goal is to drive sales and conversions. I will create engaging descriptions of the products, highlighting their features, benefits, and unique selling points."/>
                <Service anim="lineUp2" bg="bg-white" img="./images/youtube.png" head="Youtube Copywriting" 
                text="Our objective is to engage subscribers, increase viewership, and drive traffic to the channel. I will write compelling subject lines and email content that entices subscribers to watch the latest video."/>
                <Service anim="lineUp3" bg="bg-blue-main" img="./images/product_adviser2.png" head="Product Adviser Copywriting" tcolor="text-white" 
                text="Our focus is on creating persuasive emails that generate leads, drive traffic, or encourage consumers to take specific actions. Depending on your objective, I may aim to generate leads, traffic to landing page or immediate purchases."/>
            
            </div>
            <div className='ml-auto mr-auto justify-center flex'>
            <Service anim="lineUp4" bg="bg-blue-main" img="./images/fitness.png" head="Fitness Copywriting" tcolor="text-white" 
                text="Our focus is to foster a strong sense of community among clients and prospects. I can send fitness tips, workout routines, healthy recipes, and success stories to keep subscribers engaged and motivated."/>
            </div>
            <div className='flex justify-center items-center mt-24 lg:mt-24'>
                <button class="btn slide-in-long-2" onClick={openCalendly}>Book a free call</button>
            </div>
        </div>
    )
}