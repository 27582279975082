import React from 'react'
import Form from 'react-bootstrap/Form';
import { useInView } from 'react-intersection-observer'

export default function Newsletter() {

    const { ref: formAnim, inView: formAnimIsVisible} = useInView({"threshold": 0, "triggerOnce": true})


    function openCalendly() {
        console.log("LOL")
        window.open('https://calendly.com/copywriter_jm/email?back=1&month=2023-06')
    }

    return(
        <div>
            <script src="https://f.convertkit.com/ckjs/ck.5.js"/>
            <div ref={formAnim} className={`${formAnimIsVisible ? 'slide-in-long' : ''}`}>
                <Form action="https://app.convertkit.com/forms/5372760/subscriptions" class="seva-form formkit-form" method="post" data-sv-form="5372760" data-uid="cdd15ed2d5" data-format="inline" data-version="5" data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;message&quot;,&quot;success_message&quot;:&quot;Success! Now check your email to confirm your subscription.&quot;,&quot;redirect_url&quot;:&quot;&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;fathom&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null,&quot;sparkloop&quot;:null,&quot;googletagmanager&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:true,&quot;url&quot;:&quot;https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;show&quot;,&quot;custom_content&quot;:&quot;&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}" min-width="400 500 600 700 800">
                    <div></div>
                    <div className='text-center mt-48 bg-blue-main 2xl:ml-96 2xl:mr-96 lg:ml-60 lg:mr-60 sm:ml-10 sm:mr-10 pt-20 pb-20 rounded-2xl grid justify-center items-center'>
                    <div data-style="minimal"><div data-element="header">
                            <h2 className='text-6xl font-semibold text-center lg:text-5xl text-white'>Join the Newsletter</h2>
                        </div><div class="formkit-subheader" data-element="subheader">
                            <p className='lg:text-xl sm:mt-4 lg:mb-2 lg:mt-2 mb-8 text-white text-3xl pl-6 pr-6'>Gain valuable mindset tips and strategies that will help you on your path to success.</p>

                        </div><ul data-element="errors" data-group="alert"></ul><div data-element="fields" data-stacked="true"><div>
                            <input class="formkit-input" name="fields[first_name]" aria-label="Name" placeholder="First Name" required="" type="test"
                                className='border-7 pl-3 pt-8 pb-8 lg:pt-4 lg:pb-4 outline-none border-blue-main text-4xl lg:text-xl sm:text-3xl rounded-lg'/>
                            <input class="formkit-input" aria-label="Email Adress" name="email_address" required="" placeholder="Email Adress" type="email" 
                                className='border-7 pl-3 pt-8 pb-8 lg:pt-4 lg:mt-4 lg:pb-4 outline-none border-blue-main text-4xl lg:text-xl w-96 ml-3 sm:text-3xl rounded-lg'/>
                        </div>

                        <button data-element="submit" className='mt-6 lg:text-2xl bg-white rounded-xl pl-8 pr-8 pt-3 pb-3 text-blue-main font-semibold sm:text-3xl'>
                            <div><div></div><div></div><div></div></div><span>Subscribe Now</span>
                        </button>
                    </div>
                    </div></div>
                </Form>
            </div>

            <div className='flex justify-center items-center mt-12'>
                    <button class="btn slide-in-long-3" onClick={openCalendly}>Book a free call</button>
                </div>
        </div>
    )
}