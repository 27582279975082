import React, { useRef, useEffect, useState} from 'react'
import emailjs from '@emailjs/browser';
import { useInView } from 'react-intersection-observer'
import Form from 'react-bootstrap/Form';


export default function Main() {

    const form = useRef()

    const { ref: checkOne, inView: checkOneIsVisible} = useInView({"threshold": 0,
    "triggerOnce": true})
    const { ref: check2, inView: check2IsVisible} = useInView({"threshold": 0,
    "triggerOnce": true})
    const { ref: check3, inView: check3IsVisible} = useInView({"threshold": 0,
    "triggerOnce": true})

    const { ref: process1, inView: process1IsVisible} = useInView({"threshold": 0,
    "triggerOnce": true})
    const { ref: process2, inView: process2IsVisible} = useInView({"threshold": 0,
    "triggerOnce": true})
    const { ref: process3, inView: process3IsVisible} = useInView({"threshold": 0,
    "triggerOnce": true})

    const { ref: process1image, inView: process1imageIsVisible} = useInView({"threshold": 0,
    "triggerOnce": true})
    const { ref: process2image, inView: process2imageIsVisible} = useInView({"threshold": 0,
    "triggerOnce": true})
    const { ref: process3image, inView: process3imageIsVisible} = useInView({"threshold": 0,
    "triggerOnce": true})
    const { ref: process4image, inView: process4imageIsVisible} = useInView({"threshold": 0,
    "triggerOnce": true})
    const { ref: process5image, inView: process5imageIsVisible} = useInView({"threshold": 0,
    "triggerOnce": true})

    const { ref: buttonAnim, inView: buttonAnimIsVisible} = useInView({"threshold": 0,
    "triggerOnce": true})
    const { ref: formAnim, inView: formAnimIsVisible} = useInView({"threshold": 0,
    "triggerOnce": true})




    const [width, setWidth] = useState(window.innerWidth);

    const isMobile = width <= 1025;


    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_roddf4b', 'template_eq13abh', form.current, '8Ri3AvHmAD1z-t462')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
    }

    function openCalendly() {
        console.log("LOL")
        window.open('https://calendly.com/copywriter_jm/email?back=1&month=2023-06')
    }

    return(
        <div>
            <div className='grid justify-center items-center sm:pl-6 md:pl-0 sm:pr-6 lg:flex pt-24'>
                <div className='2xl:pl-40 2xl:pr-20 lg:pl-28 lg:pr-10 sm:pl-12 sm:pr-12'>
                    {/* <h1 className=' sm:text-7xl font-bold hero-text sm:text-center sm:mt-24 lg:text-6xl 2xl:text-6xl lg:mt-0 lg:text-left lineUp2'>Expert Email Copywriter</h1> */}
                    <h1 className=' sm:text-7xl font-bold hero-text sm:text-center sm:mt-36 lg:text-6xl 2xl:text-6xl lg:mt-0 lg:text-left overflow-hidden bg-white'>
                        <span className=' inline-block lineUp'>The Email Copywriter For You</span>
                    </h1>
                    <h1 className=' sm:text-5xl text-center font-medium sm:mt-6 sm:pb-12 lg:pb-6 lg:text-2xl 2xl:text-3xl lg:mt-4 2xl:pr-12 lg:text-left overflow-hidden bg-white'>
                        <span className=' inline-block lineUp2'>I will help your business save time and money by maximizing your revenue through targeted email marketing campaigns</span>
                    </h1>
                    <div className='flex justify-center items-center mt- 2xl:mt-6 lg:mt-6 lg:block '>
                        <button class="btn lineUp3 inline-block " onClick={openCalendly}>Book a free call</button>
                    </div>
                </div>
                
                <img src="./images/jordan2.png" className='w-7/12 ml-auto mr-auto mt-28 lg:hidden sm:block'/>
                <img src="./images/jordan2.png" className='w-3/12 ml-auto mr-auto mt-16 lg:block 2xl:mr-40 lg:mr-20 sm:hidden slide-in z-minus'/>
            </div>
            <div className='grid justify-center items-center sm:pl-6 sm:pr-6 bg-blue-main'>
            
                <h1 className='text-center text-5xl text-white mt-16 pl-6 pr-6 pb-16 2xl:text-4xl 2xl:mt-12 2xl:pb-12 lg:pl-20 lg:pr-20 lg:text-3xl lg:mt-12 lg:pb-12 overflow-hidden bg-blue-main'>
                Whether you sell Gumroad products, a fitness coaching service or sweets in a corner shop, I can help you. I have the expertise to craft compelling copy that drives 
                conversion and generates results. Because I believe in the impact of my product, I offer a 100% money-back guarantee upon the unlikely failure to do so.</h1>
            </div>
            <div className='mt-24 pl-12 pr-12 lg:mt-24 2xl:pl-80 2xl:pr-80 lg:pl-40 lg:pr-40 mb-24'>
                <h1 className='text-7xl font-semibold lg:text-5xl'>Working with me:</h1>
                <div className='flex mt-6 pb-6 lg:mt-10 overflow-hidden bg-white'>
                    <img src="./images/check2.png" className={` w-16 h-16 mt-auto mb-auto mr-6 lg:w-14 lg:h-14 inline-block `}/>
                    <h1 ref={checkOne} className='text-5xl font-medium lg:text-3xl lg:mt-auto lg:mb-auto '>
                        <span className={`inline-block ${checkOneIsVisible ? `lineUp` : ''} invisible`}>I focus on making the most revenue for you while using the best writing strategy.</span></h1>
                </div>
                <div className='flex mt- overflow-hidden bg-white'>
                    <img src="./images/check2.png" className={`w-16 h-16 mt-auto mb-auto mr-6 lg:w-14 lg:h-14 `}/>
                    <h1 ref={check2} className='text-5xl font-medium lg:text-3xl lg:mt-auto lg:mb-auto '>
                        <span className={`inline-block ${check2IsVisible ? `lineUp2` : ''} invisible`}>I help you find your voice in the industry.</span></h1>
                </div>
                <div className='flex mt-6 overflow-hidden bg-white'>
                    <img src="./images/check2.png" className={` w-16 h-16 mt-auto mb-auto mr-6 lg:w-14 lg:h-14 `}/>
                    <h1 ref={check3} className='text-5xl font-medium lg:text-3xl lg:mt-auto lg:mb-auto'>
                        <span className={`inline-block ${check3IsVisible ? `lineUp3` : ''} invisible`}>I adapt my writing style to suit various industries.</span></h1>
                </div>
            </div>
            <div className='grid justify-center items-center sm:pl-0 sm:pr-0 lg:pl-0 lg:pr-0 bg-blue-main'>
                <h1 className='text-center font-bold text-7xl text-white mt-24 pl-6 pr-6 lg:text-5xl 2xl:mt-20'>My process for delivering good results</h1>
                <div className={`lg:flex lg:mt-24 lg:mb-12 sm:pl-6 sm:pr-6 lg:pl-0 lg:pr-0 sm:pb-24 lg:pb-0`} >
                    <img ref={process1image} src="./images/startup.png" className={`flex ml-auto mr-auto w-3/12 mt-16 lg:hidden sm:block  ${process1imageIsVisible ? 'slide-in' : ''} invisible `}/>
                    <div className={`2xl:pl-60 lg:pl-40 mt-auto mb-auto bg-blue-main overflow-hidden`}>
                        <h1 ref={process1} className={`text-7xl text-white sm:text-center mt-12 lg:text-4xl 2xl:text-5xl lg:text-left lg:pl-0 lg:mt-0 invisible lg:font-medium ${process1IsVisible ? 'lineUp' : ''}`}>1. Starting up</h1>
                        <h1 className={`text-5xl text-white text-center mt-12 sm:pl-6 lg:pl-0 pr-6 2xl:pr-0 lg:text-3xl lg:mt-4 2xl:mt-6 lg:text-left invisible ${process1IsVisible ? 'lineUp2' : ''}`}>
                        Let’s jump on a call and have a conversation about what you are trying to achieve. Once you realise the impact of my service can have, I will do the necessary due diligence to gain insight into your industry, competitors and target audience.</h1>
                    </div>
                    <img ref={process4image} src="./images/startup.png" className={`flex 2xl:ml-32 pt-2 w-44 w sm:hidden lg:block 2xl:mr-60 lg:ml-28 invisible lg:mr-40 ${process4imageIsVisible ? 'slide-in' : ''}`}/>
                </div>
                <div className={`lg:flex lg:pt-16 bg-blue-main-darker sm:pl-6 sm:pr-6 lg:pl-0 lg:pr-0 pb-16`} >
                    <img ref={process2image} src="./images/strategy2.png" className={`invisible flex ml-auto mr-auto w-3/12 mt-10 lg:w-52 2xl:pt-6 2xl:pb-6 2xl:mr-40 lg:mr-28 2xl:ml-60 lg:ml-40
                     lg:mt-0 ${process2imageIsVisible ? 'slide-in' : ''}`}/>
                    <div className='2xl:pr-60 lg:pr-40 overflow-hidden'>
                        <h1 ref={process2} className={`text-7xl text-white text-center mt-2 2xl:text-5xl lg:text-4xl lg:text-left lg:pl-0 2xl:mt-16 lg:font-medium invisible ${process2IsVisible ? 'lineUp' : ''}`}>2. Strategy</h1>
                        <h1 className={`text-5xl text-white text-center mt-12 pl-6 pr-6 lg:pl-0 2xl:pr-0 lg:text-3xl lg:mt-6 lg:text-left invisible ${process2IsVisible ? 'lineUp2' : ''}`}>
                        Based on your goals, I will develop a strategic plan for the email campaign. Establishing the correct tactics, personality and creativity for your audience is key. We will be in constant communication as I discover new ideas on ways to increase your sales within the process.</h1>
                    </div>
                </div>
                <div className={`lg:flex lg:mt-16 lg:mb-12 sm:pl-6 sm:pr-6 lg:pl-0 lg:pr-0 sm:pb-24 lg:pb-0`}>
                    <img ref={process3image} src="./images/data2.png" className={`flex invisible ml-auto mr-auto w-3/12 mt-16 lg:hidden sm:block ${process3imageIsVisible ? 'slide-in' : ''}`}/>
                    <div className='2xl:pl-60 lg:pl-40 mt-auto mb-auto overflow-hidden'>
                        <h1 ref={process3} className={`text-7xl text-white sm:text-center mt-12 lg:text-4xl 2xl:text-5xl lg:text-left lg:pl-0 lg:mt-0 lg:font-medium invisible ${process3IsVisible ? 'lineUp' : ''}`}>3. All the data</h1>
                        <h1 className={`text-5xl text-white text-center mt-12 sm:pl-6 lg:pl-0 pr-6 2xl:pr-0 lg:text-3xl 2xl:mt-6 lg:text-left lg:mt-4 invisible ${process3IsVisible ? 'lineUp2' : ''}`}>After every post, I will analyse the click-through rates, conversion rates and engagement to see what produces the desirable results and what can be improved.</h1>
                    </div>
                    <img ref={process5image} src="./images/data2.png" className={`flex invisible 2xl:ml-32 lg:ml-28 pl-6 w-60 sm:hidden lg:block 2xl:mr-60 lg:mr-40 ${process5imageIsVisible ? 'slide-in' : ''}`}/>
                </div>

            </div>

            <div className='mt-24 pl-12 pr-12 pb-24 lg:pb-20 2xl:mt-12 lg:mt-20'>
                <h1 className='text-7xl font-semibold text-center lg:text-5xl'>Still have questions?</h1>
                <div className='flex justify-center items-center mt-24 lg:mt-12'>
                    <button ref={buttonAnim} className={`btn ${buttonAnimIsVisible ? 'slide-in-long' : ''} invisible`} onClick={openCalendly}>Book a free call</button>
                </div>
            </div>
            <div className='pl-12 pr-12'>
                <h1 className='text-7xl font-semibold text-center mb-24 lg:mb-12 lg:text-5xl'>Or send me a message directly</h1>
                <div ref={formAnim} className={`${formAnimIsVisible ? 'slide-in-long' : ''} invisible`}>
                    <form className={` flex flex-col w-12/12 rounded-3xl bg-blue-main 2xl:w-8/12 lg:w-10/12 lg:ml-auto lg:mr-auto`} onSubmit={sendEmail} ref={form}>
                        <input type="text" name="name" placeholder='Your Name' required className='border-4 pl-3 pt-8 pb-8 lg:pt-4 lg:pb-4 outline-none border-blue-main text-4xl lg:text-2xl rounded-t-3xl'/>
                        <input type="text" name="email" placeholder='Email' required className='border-4 border-t-0 pl-3 pt-8 pb-8 lg:pt-4 lg:pb-4 outline-none border-blue-main text-4xl lg:text-2xl'/>
                        <input type="text" name="twitter" placeholder='Twitter' required className='border-4 border-t-0 pl-3 pt-8 pb-8 lg:pt-4 lg:pb-4 outline-none border-blue-main text-4xl lg:text-2xl'/>
                        <textarea name="message" row="4" placeholder='Explain your business briefly' className='border-4 border-t-0 pl-3 pt-8 pb-12 lg:pb-6 lg:pt-4 outline-none border-blue-main lg:text-2xl text-4xl'></textarea>
                        <button type='submit' className='pt-8 pb-8 2xl:pt-4 2xl:pb-4 lg:pt-6 lg:pb-6 bg-blue-main text-white color-blue-main text-4xl font-semibold outline-none cursor-pointer rounded-b-3xl lg:text-2xl'>Send</button>
                    </form>
                </div>
            </div>

            <div>
            <script src="https://f.convertkit.com/ckjs/ck.5.js"/>
            <Form action="https://app.convertkit.com/forms/5372760/subscriptions" class="seva-form formkit-form" method="post" data-sv-form="5372760" data-uid="cdd15ed2d5" data-format="inline" data-version="5" data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;message&quot;,&quot;success_message&quot;:&quot;Success! Now check your email to confirm your subscription.&quot;,&quot;redirect_url&quot;:&quot;&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;fathom&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null,&quot;sparkloop&quot;:null,&quot;googletagmanager&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:true,&quot;url&quot;:&quot;https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;show&quot;,&quot;custom_content&quot;:&quot;&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}" min-width="400 500 600 700 800">
                    <div></div>
                    <div className='text-center mt-24 bg-blue-main 2xl:ml-96 2xl:mr-96 lg:ml-60 lg:mr-60 sm:ml-10 sm:mr-10 pt-20 pb-20 rounded-2xl grid justify-center items-center'>
                        <div data-style="minimal"><div data-element="header">
                            <h2 className='text-6xl font-semibold text-center lg:text-5xl text-white'>Join the Newsletter</h2>
                        </div><div class="formkit-subheader" data-element="subheader">
                            <p className='lg:text-xl sm:mt-4 lg:mb-6 lg:mt-2 mb-8 text-white text-3xl pl-6 pr-6'>Gain valuable mindset tips and strategies that will help you on your path to success.</p>

                        </div><ul data-element="errors" data-group="alert"></ul><div data-element="fields" data-stacked="true"><div>
                            <input class="formkit-input" name="fields[first_name]" aria-label="Name" placeholder="First Name" required="" type="test"
                                className='border-7 pl-3 pt-8 pb-8 lg:pt-4 lg:pb-4 outline-none border-blue-main text-4xl lg:text-xl sm:text-3xl rounded-lg'/>
                            <input class="formkit-input" aria-label="Email Adress" name="email_address" required="" placeholder="Email Adress" type="email" 
                                className='border-7 pl-3 pt-8 pb-8 lg:pt-4 lg:pb-4 outline-none border-blue-main text-4xl lg:text-xl w-96 ml-3 sm:text-3xl rounded-lg'/>
                        </div>

                        <button data-element="submit" className='mt-6 lg:text-2xl bg-white rounded-xl pl-8 pr-8 pt-3 pb-3 text-blue-main font-semibold sm:text-3xl'>
                            <div><div></div><div></div><div></div></div><span>Subscribe Now</span>
                        </button>
                    </div>
                    </div></div>
                </Form>
            </div>
        </div>
    )
}