import React from 'react'
import {Route, Routes} from 'react-router-dom'
import Navbar from './components/Navbar'
import Main from './components/Main'
import About from './components/About'
import Footer2 from './components/Footer2'
import Contact from './components/Contact'
import Service from './components/Services'
import Newsletter from './components/Newsletter'

export default function App() {
    return(
        <div>
            <Navbar/>
            <Routes>
                <Route path="/" element={<Main/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/services" element={<Service/>}/>
                <Route path="/newsletter" element={<Newsletter/>}/>
            </Routes>
            <Footer2/>
        </div>
    )
}