import React, { useState, useRef, useEffect } from 'react'
import emailjs from '@emailjs/browser';
import { useInView } from 'react-intersection-observer'

export default function Contact() {
    const form = useRef()

    const { ref: formAnim, inView: formAnimIsVisible} = useInView({"threshold": 0, "triggerOnce": true})

    function openCalendly() {
        console.log("LOL")
        window.open('https://calendly.com/copywriter_jm/email?back=1&month=2023-06')
    }

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_roddf4b', 'template_eq13abh', form.current, '8Ri3AvHmAD1z-t462')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
    }

    return(
        <div>
            <div className='pl-12 pr-12 mt-48'>
                <h1 className='text-7xl font-semibold text-center mb-24 lg:mb-12 lg:text-5xl'>Send me a message</h1>
                <div ref={formAnim} className={`${formAnimIsVisible ? 'slide-in-long' : ''}`}>
                    <form className=' flex flex-col w-12/12 rounded-3xl bg-blue-main 2xl:w-8/12 lg:w-10/12 lg:ml-auto lg:mr-auto' onSubmit={sendEmail} ref={form}>
                        <input type="text" name="name" placeholder='Your Name' required className='border-4 pl-3 pt-8 pb-8 lg:pt-4 lg:pb-4 outline-none border-blue-main text-4xl lg:text-2xl rounded-t-3xl'/>
                        <input type="text" name="email" placeholder='Email' required className='border-4 border-t-0 pl-3 pt-8 pb-8 lg:pt-4 lg:pb-4 outline-none border-blue-main text-4xl lg:text-2xl'/>
                        <input type="text" name="twitter" placeholder='Twitter' required className='border-4 border-t-0 pl-3 pt-8 pb-8 lg:pt-4 lg:pb-4 outline-none border-blue-main text-4xl lg:text-2xl'/>
                        <textarea name="message" row="4" placeholder='Explain your business briefly' className='border-4 border-t-0 pl-3 pt-8 pb-12 lg:pb-6 lg:pt-4 outline-none border-blue-main lg:text-2xl text-4xl'></textarea>
                        <button type='submit' className='pt-8 pb-8 2xl:pt-4 2xl:pb-4 lg:pt-6 lg:pb-6 bg-blue-main text-white color-blue-main text-4xl font-semibold outline-none cursor-pointer rounded-b-3xl lg:text-2xl'>Send</button>
                    </form>
                </div>
            </div>

            <div className='flex justify-center items-center mt-12'>
                    <button class="btn slide-in-long-3" onClick={openCalendly}>Book a free call</button>
                </div>
        </div>
    )
}